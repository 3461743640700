import { AutoAwesomeOutlined, Chat, ChatOutlined, Edit, TextSnippetOutlined } from "@mui/icons-material";
import { Badge, Box, Card, CircularProgress, Link, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import Feedback from "../Feedback";
import { ChatInteraction, FocusedInteraction } from "../models";
import PMREvidences from "../PMREvidences";
import useMarketResearchStore from "../useMarketResearchStore";
import { scrollQuestionIntoView } from "./StudyChat";

interface ChatAnswerNodeProps {
  interaction: ChatInteraction;
  anchorId: string;
  isLoading: boolean;
  loadingStatus: string;
  fromSummary?: boolean;
  numOfResponses?: number;
}

export default function ChatAnswerNode({
  interaction,
  anchorId,
  isLoading,
  loadingStatus,
  fromSummary = false,
  numOfResponses = undefined,
}: ChatAnswerNodeProps) {
  const { editQuestion } = useMarketResearchStore();
  const [focusedInteraction, setFocusedInteraction] = useAtom(FocusedInteraction);

  const { ref, inView } = useInView({ threshold: 0.8 });

  useEffect(() => {
    if (inView && focusedInteraction !== interaction) {
      // Setting this value updates which Question is highlighted in <ChatSessionOutline />
      setFocusedInteraction(interaction);
    }
  }, [inView]);

  return (
    <Box>
      {!fromSummary && (
        <>
          <Box sx={{ display: "flex", marginTop: "2rem", flex: 1 }} justifyContent={"space-between"} ref={ref}>
            <Link
              href={`${location.pathname}#${anchorId}`}
              id={anchorId}
              underline="none"
              onClick={(event) => {
                event.preventDefault();
                scrollQuestionIntoView(anchorId);
              }}
            >
              <Box sx={{ display: "flex" }} alignItems={"center"}>
                <Chat />
                <Typography variant="h5" sx={{ marginLeft: "0.5rem" }}>
                  Original Question
                </Typography>
              </Box>
            </Link>
            <Box sx={{ display: "flex" }} alignItems={"center"}>
              <span onClick={() => editQuestion(interaction.user_query ?? "")} style={{ cursor: "pointer" }}>
                <Edit />
              </span>
            </Box>
          </Box>
          <Card sx={{ marginTop: "1rem", display: "flex", gap: "20px", padding: "1rem", backgroundColor: "#EFF0F0" }}>
            {numOfResponses && (
              <Badge
                badgeContent={numOfResponses}
                color="secondary"
                sx={{
                  "& .MuiBadge-badge": {
                    color: "white",
                    width: "1rem",
                    height: "1rem",
                  },
                }}
              >
                <ChatOutlined fontSize="medium" sx={{ color: "#003087" }} />
              </Badge>
            )}
            <Typography sx={{ fontWeight: "700", fontSize: "14px" }}>{interaction.user_query}</Typography>
          </Card>
        </>
      )}
      <Box sx={{ alignItems: "center", mt: "2rem" }}>
        <Box>
          <Box sx={{ display: "flex" }}>
            <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-between", flex: 1 }}>
              <Box>
                <AutoAwesomeOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
                <Typography variant="h6" color={"#13294B"} display="inline-block">
                  {fromSummary ? "Deepchat Summary" : "Deepchat Answer"}
                </Typography>
              </Box>
              <Box sx={{ marginLeft: "1em", marginTop: "0.25em", flex: 1 }}>
                {isLoading && (
                  <>
                    <CircularProgress
                      size="small"
                      sx={{ gridArea: "1/1", width: "1.25rem", height: "1.25rem", verticalAlign: "sub" }}
                    />
                    <Typography sx={{ marginLeft: "0.5em", fontStyle: "italic", display: "inline-block" }}>
                      {loadingStatus}
                    </Typography>
                  </>
                )}
              </Box>
              <Box>
                <Feedback interactionId={fromSummary ? interaction.action_id! : interaction.user_chat_session} />
              </Box>
            </Box>
          </Box>
          <Card sx={{ marginTop: "1rem", marginBottom: "1rem" }}>
            <Box sx={{ padding: "1rem" }}>{formatResponseText(interaction.answer ?? "")}</Box>
          </Card>
        </Box>
      </Box>

      {interaction?.evidence?.length && !fromSummary && (
        <Box sx={{ display: "flex", flexDirection: "column", mt: "2rem", gap: "0.25rem" }}>
          <Box sx={{ display: "flex", alignItems: "center", gap: "10px" }}>
            <TextSnippetOutlined />
            <Typography variant="h6" color={"#13294B"} sx={{ fontSize: "20px" }}>
              Relevant Text Records
            </Typography>
          </Box>
          <Box>
            <PMREvidences evidences={interaction?.evidence ?? []} />
          </Box>
        </Box>
      )}
    </Box>
  );
}

const formatResponseText = (text: string) => {
  const response = (text || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }

    if (segment.endsWith(":")) {
      return (
        <Typography sx={{ fontWeight: "bold" }} key={i}>
          {segment}
        </Typography>
      );
    }

    return (
      <Typography py={isBlankLine ? "0.5em" : 0} key={i}>
        {segment}
      </Typography>
    );
  });

  return response;
};
