export const RouteId = {
  Root: "root",
  Deepchat: {
    Root: "deepchat",
    AskQuestion: "deepchat.ask-question",
    SessionThread: "deepchat.session-thread",
    SummarizeDocument: "deepchat.summarize-document",
    QuerySales: "deepchat.query-sales",
  },
  SalesData: {
    Root: "sales-data",
    AskQuestion: "sales-data.ask-question",
    Session: "sales-data.session",
  },
  Explore: { Root: "explore" },
  MarketResearch: {
    Root: "market-research",
    Studies: "market-research.studies",
    ViewStudy: "market-research.view-study",
    Chat: "market-research.chat",
    ViewTranscript: "market-research.view-transcript",
    StudySummary: "market-research.study-summary",
    QAChat: "market-research.qa-chat",
    GenerateSummary: "market-research.generate-summary",
  },
  Retros: { Root: "retros" },
  Dashboards: {
    Root: "dashboards.custom",
    Landing: "dashboards.landing",
  },
};
