import { UUID } from "crypto";
import React from "react";
import { CommonCardProps, SearchFilters } from "../../models/search";

export type ActivityType =
  | "search"
  | "deepchat"
  | "pmr_chat"
  | "executive_summary"
  | "portfolio_summary"
  | "structured_data";

export interface SourceDocument {
  source_text: string; //"Looking for information on Actemra issues.",
  join_id: string; //"voc_digital_commercial-2115304",
  customer_type: string; //"Patient or Caregiver",
  data_source: string; //"VOC Digital Commercial",
  digital_reason_score_cmt: string; //"Looking for information on Actemra issues.",
  ecosystem_name?: string; // null,
  hcp_descriptor?: string; // null,
  leibniz_filter_date: number; // 1670955281,
  native_id: string; //"voc_digital_commercial-2115304",
  oasis_load_date: string; //"2024-03-02 14:19:25.950",
  patient_descriptor: string; //"Currently taking this medication / product (one on website) for this condition",
  product_url: string; //"www.actemra.com",
  region?: string; // null,
  responsedate: string; //"2022-12-13 18:14:41.000",
  role_type?: string; //null,
  sub_indication: string; // null,
  surveyid: string; //"2115304",
  source_filter_date: string; //"2022-12-13 18:14:41.000",
  embedding: number[];
  text: string; //"Looking for information on Actemra issues.",
  indication: string; // null,
  indication_imputed: string; //"No",
  indication_score: number; // 0.7945405738,
  product_molecule: string; //"ACTEMRA",
  product_molecule_imputed: string; //"Yes",
  product_molecule_score: number; // 0.9097611732,
  therapeutic_area: string; // null,
  therapeutic_area_imputed: string; //"No",
  therapeutic_area_score: number; // 0.7850027888,
  sentiment: string; // "neutral",
  text_summary: string; // null,
  all_topics: string[]; // [ "Autoimmune Disease Management" ],
  keywords: string[];
  topics: string; //"Autoimmune Disease Management"
  subtopic: string;
  submitted_by?: string;
  account_name?: string;
}

export interface ChatResponse {
  user_query?: string;
  user_id: string;
  chat_feedback?: number;
  user_chat_session: UUID;
  timestamp?: number;
  sources: SourceDocument[];

  search_object?: SearchFilters;
  response?: string;
}

export interface ChatResponseAnswerPartial {
  answer: string;
}

export interface ChatRequest {
  query: string;
  search_request: SearchFilters;
  user_chat_session?: UUID | null;
}

// TODO: Determine if we want this here
export interface SalesDataChatRequest {
  user_question: string;
  refresh_sales_cache: boolean;
}

export interface ThreadFeedback {
  feedback?: number; // 1 or -1
  action_id?: UUID;
}

export interface CalloutProps {
  // Where is the pointer
  x: "left" | "right";
  y: "top" | "bottom";
  // Along which axis is the pointer pointing
  axis: "x" | "y";
  // Where is the Callout positioned relative to the child.
  // Will generally need a top or bottom and a left or right.
  placement: { top?: string; left?: string; bottom?: string; right?: string };
}

export type TCommonTooltipContent = {
  header: string;
  desc: React.ReactNode;
};

export interface QueryEnhancerResponse {
  action_id: UUID;
  original_query: string;
  enhanced_queries: string[];
}

export type TPortfolioSummary = {
  summary: string;
  action_id?: UUID;
};

export type TRecentDocumentsHistory = {
  document_id: string;
  user_id: string;
  user_email: string;
  session_id: string;
  s3_filename: string;
  s3_document_url: string;
  s3_summary_url: any;
  original_document_name: string;
  document_status: string;
  create_datetime: string;
  update_datetime: string;
};

export type TGetSignedS3UrlResponse = {
  document_id: string;
  user_id: string;
  user_email: string;
  session_id: string;
  s3_filename: string;
  s3_document_url: string;
  s3_summary_url: any;
  original_document_name: string;
  document_status: string;
  create_datetime: string;
  update_datetime: string;
};

export type TCompressionLevel = {
  id: string;
  value: string;
  isSelected: boolean;
};

export type TMRSummaryButton = {
  title: string;
  toolTipDesc: string;
  onClick: () => void;
};

export type TMRRightMenu = {
  showMenu: boolean;
} & TMRSummaryButton;

export const mapSourceDocumentToCommonCard = (source: SourceDocument): CommonCardProps => ({
  _id: source.join_id,
  text: source.text,
  text_summary: source.text_summary,
  sentiment: source.sentiment,
  submitted_by: source.submitted_by,
  account_name: source.account_name,
  children: undefined,
  data_source: source.data_source,
  topics: source.topics,
  subtopic: source.subtopic,
});
