import { ArticleOutlined, ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, Typography } from "@mui/material";
import React, { useState } from "react";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import VerbatimCard from "../../cards/VerbatimsCard/VerbatimCard";
import { mapSourceDocumentToCommonCard, SourceDocument } from "../models";

export interface SupportingDocumentsProps {
  sources: SourceDocument[];
}

const SupportingDocumentsBase = ({ sources }: SupportingDocumentsProps) => {
  const [supportingDocumentsExpanded, setSupportingDocumentsExpanded] = useState(false);

  return (
    <>
      <Box mb="1em">
        <ArticleOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
        <Typography variant="h6" color={"#13294B"} display="inline-block">
          Supporting Documents
        </Typography>
        <Box
          sx={{ display: "inline-block", verticalAlign: "middle", marginLeft: "0.5em", cursor: "pointer" }}
          onClick={() => setSupportingDocumentsExpanded((last) => !last)}
        >
          {supportingDocumentsExpanded ? <ExpandLess /> : <ExpandMore />}
        </Box>
      </Box>
      <Box>
        <Collapse in={supportingDocumentsExpanded}>
          <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 3, 2000: 4 }}>
            <Masonry gutter="15px">
              {(sources ?? []).map((record, i) => {
                return (
                  <VerbatimCard record={mapSourceDocumentToCommonCard(record)} fromSupportingDocs={true} key={i} />
                );
              })}
            </Masonry>
          </ResponsiveMasonry>
        </Collapse>
      </Box>
    </>
  );
};

export default React.memo(
  SupportingDocumentsBase,
  (prevProps: Readonly<SupportingDocumentsProps>, nextProps: Readonly<SupportingDocumentsProps>) => {
    return prevProps.sources === nextProps.sources;
  },
);
