import { useAtom } from "jotai";
import { useMemo } from "react";
import {
  QuestionIsLoadingAtom,
  QuestionTargetSalesData,
  QuestionTextAtom,
} from "../../components/Deepchat/state/DeepchatState";
import useLocalFilterState from "../../components/filters/useLocalFilterState";
import { useDeepChatSocketContext } from "../../contexts/DeepChatSocketContext";
import { useFilterContext } from "../../contexts/FilterProvider";
import useDeepchat from "../../contexts/useDeepchat";
import { commercialQuestions, medicalQuestions, salesDataQuestions } from "../../constants/sampleQuestions";
import useUserContext from "../../components/Authorization/useUserContext";
import { useNavigate } from "react-router-dom";

export const useSampleQuestionStore = () => {
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();
  const user = useUserContext();

  let targetRole = user.selectedRoles.at(0) ?? "";

  const [checkboxSalesData] = useAtom(QuestionTargetSalesData);

  const [loading, setLoading] = useAtom(QuestionIsLoadingAtom);
  const [, setQuestion] = useAtom(QuestionTextAtom);
  const { askQuestionStreaming } = useDeepchat();
  const { filterState, applyFilters } = useFilterContext();
  const { transientFilterState } = useLocalFilterState(filterState);

  if (checkboxSalesData) {
    targetRole = "sales_data";
  }

  const questionRoleMap = new Map([
    ["commercial", commercialQuestions],
    ["medical", medicalQuestions],
    ["sales_data", salesDataQuestions],
  ]);

  const questions = questionRoleMap.get(targetRole) ?? [];

  const getRandomSubset = <T>(arr: T[], count: number): T[] => {
    return arr
      .map((x) => ({ x, r: Math.random() }))
      .sort((a, b) => a.r - b.r)
      .map((a) => a.x)
      .slice(0, count);
  };
  const randomQuestion = useMemo(() => getRandomSubset(questions, 3), [targetRole]);

  const submitQuestion = (question: string) => {
    // IF "sales_data", submit the question via the URI to the `/salesdata` app section
    if (targetRole == "sales_data") {
      return navigate(`/salesdata/?question=${question}`);
    }

    // ELSE follow normal Deepchat flow for submitting questions
    if (!loading) {
      setLoading(true);
      setQuestion(question);
      if (transientFilterState) {
        applyFilters(transientFilterState);
      }
      setTimeout(() => {
        askQuestionStreaming(DeepChatSocket.sendJsonMessage, transientFilterState ?? filterState, question);
      }, 200);
    }
  };

  return {
    randomQuestion,
    submitQuestion,
  };
};
