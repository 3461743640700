import {
  AutoAwesomeOutlined,
  LibraryAddCheckOutlined,
  PersonPinSharp,
  SvgIconComponent,
  TextSnippetOutlined,
} from "@mui/icons-material";
import { Box, CircularProgress, CssBaseline, Divider, Grid, ThemeProvider, Typography } from "@mui/material";
import { TopBar } from "../components";
import Roles from "../components/Authorization/Roles";
import { Feature } from "../featureFlags/featureFlagConfig";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import { theme } from "../theme";
import { hasDatePassed } from "../utils/utils";
import { TOP_BAR_HEIGHT } from "./AppLayout";

type RoleSelectorProps = {
  setUsersDomainRole: (domainRole: string, urlRedirect?: string) => void;
  userRoles: string[];
};

const GradientOpenWithIcon = (Icon: SvgIconComponent & { fontSize?: string }) => (props: any) => (
  <>
    <svg width={0} height={0}>
      <linearGradient id="linearColors" x1={1} y1={1} x2={0} y2={0}>
        <stop offset={0} stopColor="rgba(0,48,135,1)" />
        <stop offset={0.5} stopColor="rgba(0,163,224,1)" />
        <stop offset={1} stopColor="rgba(141,200,232,1)" />
      </linearGradient>
    </svg>
    <Icon sx={{ fill: "url(#linearColors)" }} />
  </>
);

const RoleSelectorPage = ({
  setUsersDomainRole,
  userRoles,
  isLoading,
}: RoleSelectorProps & { isLoading: boolean }): JSX.Element => {
  const pmr_feature = useFeatureFlag(Feature.PrimaryMarketResearch);
  const structuredData_feature = useFeatureFlag(Feature.StructuredData);

  const ROLE_TYPES = [
    {
      name: "Chat with Commercial Data",
      roles: [Roles.COMMERCIAL],
      icon: GradientOpenWithIcon(AutoAwesomeOutlined),
      isNewUntilDate: "2025-01-01",
      desc: "Ask questions about the information from commercial Veeva and VOC data sources, and get concise answers with cited sources.",
    },
    {
      name: "Chat with Medical Data",
      roles: [Roles.MEDICAL],
      icon: GradientOpenWithIcon(AutoAwesomeOutlined),
      isNewUntilDate: "2025-01-01",
      desc: "Ask questions about the information from medical Veeva, Synapse, VOC, and even Twitter and get concise answers with cited sources.",
    },
    {
      name: "Explore Trending Topics: Commercial Data",
      roles: [Roles.COMMERCIAL],
      urlRedirect: "/explorer",
      icon: GradientOpenWithIcon(TextSnippetOutlined),
      isNewUntilDate: "2025-01-01",
      desc: "Specify your area of interest by therapeutic area, product or molecule, indication, ecosystem, customer or account type, and more, then explore insights.",
    },
    {
      name: "Explore Trending Topics: Medical Data",
      roles: [Roles.MEDICAL],
      urlRedirect: "/explorer",
      icon: GradientOpenWithIcon(TextSnippetOutlined),
      isNewUntilDate: "2025-01-01",
      desc: "Specify your area of interest by therapeutic area, product or molecule, indication, ecosystem, customer or account type, and more, then explore insights.",
    },
    {
      name: "Customize Commercial Dashboards",
      roles: [Roles.COMMERCIAL],
      icon: GradientOpenWithIcon(LibraryAddCheckOutlined),
      isNewUntilDate: "2025-01-01",
      urlRedirect: "/dashboard/?action=new",
      desc: "Choose from a range of data visualizations and arrange them on the page how you like. Then interact with MedInfo data to uncover new insights.",
    },
    {
      name: "Customize Medical Dashboards",
      roles: [Roles.MEDICAL],
      icon: GradientOpenWithIcon(LibraryAddCheckOutlined),
      isNewUntilDate: "2025-01-01",
      urlRedirect: "/dashboard/?action=new",
      desc: "Choose from a range of data visualizations and arrange them on the page how you like. Then interact with MedInfo data to uncover new insights.",
    },
    ...(structuredData_feature.enabled
      ? [
          {
            name: "Chat with Sales Segments",
            roles: [Roles.COMMERCIAL],
            icon: GradientOpenWithIcon(AutoAwesomeOutlined),
            isNewUntilDate: "2025-04-01",
            urlRedirect: "/deepchat/?salesdata=1",
            desc: "Ask questions about quantifiable sales data to explore trends, discover growing accounts, and understand changes over time.",
          },
        ]
      : []),
    ...(pmr_feature.enabled
      ? [
          {
            name: "Understand Primary Market Research",
            roles: [Roles.PRIMARY_MARKET_RESEARCH],
            icon: GradientOpenWithIcon(PersonPinSharp),
            isNewUntilDate: "2025-04-01",
            desc: "Ask questions and generate summaries across different personas, specialties, practice types, and target priorities to understand key market trends.",
          },
        ]
      : []),
  ];

  return (
    <ThemeProvider theme={theme}>
      <Box sx={{ display: "flex", flexDirection: "column", flex: 1, backgroundColor: "white", minHeight: "100vh" }}>
        <CssBaseline />
        <TopBar hideSearchBar showWelcome={false} />
        <Box
          display="flex"
          flexDirection={"column"}
          sx={{
            flex: 1,
            px: "20rem",
            height: "100vh",
            marginTop: `${TOP_BAR_HEIGHT}px`,
            py: "10px",
          }}
        >
          <Typography
            sx={{
              fontSize: "36px",
              fontWeight: 400,
              color: "#13294B",
              textAlign: "center",
              lineHeight: "40.21px",
              background: "linear-gradient(0deg, #0B41CD 12.9%, #00A3E0 52.56%)",
              WebkitBackgroundClip: "text",
              WebkitTextFillColor: "transparent",
            }}
          >
            Welcome to Deepsense
          </Typography>
          <Box>
            {isLoading && userRoles && (
              <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center", mt: "2rem" }}>
                <CircularProgress sx={{ mt: 1, mb: 5, alignItems: "center", justifyContent: "center" }} />
              </Box>
            )}
            <Grid container spacing={4} sx={{ mt: 1, mb: 5, alignItems: "center", justifyContent: "center" }}>
              {ROLE_TYPES.filter((roleType) => userRoles.includes(roleType.roles.at(0) ?? "")).map((roleType) => (
                <Grid
                  item
                  key={roleType.name}
                  xs={6}
                  onClick={() => setUsersDomainRole(roleType.roles[0], roleType.urlRedirect)}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      width: "100%",
                      height: "auto",
                      background: "rgba(239, 240, 240, 1)",
                      borderRadius: "8px",
                      py: 3,
                      px: 4,
                      minHeight: "180px",
                      position: "relative",
                      overflow: "hidden",
                      cursor: "pointer",
                    }}
                  >
                    <Box sx={{ display: "flex", alignItems: "center" }}>
                      <roleType.icon
                        fontSize="small"
                        sx={{
                          color: "linear-gradient(343.36deg, #003087 40.16%, #00A3E0 74.64%, #8DC8E8 116.43%)",
                        }}
                      />
                      <Typography
                        variant="h6"
                        sx={{ fontSize: "15px", fontWeight: "700", ml: 2, color: " rgba(84, 88, 89, 1)" }}
                      >
                        {roleType.name}
                      </Typography>
                    </Box>
                    <Divider sx={{ border: "0.5px solid var(--secondary-light, rgba(141, 200, 232, 1))", my: 2 }} />
                    <Typography
                      variant="h6"
                      sx={{ fontSize: "15px", fontWeight: "300", color: " rgba(84, 88, 89, 1)" }}
                    >
                      {roleType.desc}
                    </Typography>
                    {hasDatePassed(roleType.isNewUntilDate) && (
                      <Box
                        sx={{
                          position: "absolute",
                          background: "var(--secondary-main, rgba(0, 163, 224, 1))",
                          width: "200px",
                          height: "30px",
                          top: 10,
                          right: -70,
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          transform: "rotate(45deg)",
                        }}
                      >
                        <Typography variant="h6" sx={{ fontSize: "12px", fontWeight: "400", color: "white" }}>
                          NEW!
                        </Typography>
                      </Box>
                    )}
                  </Box>
                </Grid>
              ))}
            </Grid>
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

export default RoleSelectorPage;
