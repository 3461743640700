import { AutoAwesomeOutlined, AutoFixHigh } from "@mui/icons-material";
import { Box, Button, Link, Paper, Typography } from "@mui/material";
import { useAtom } from "jotai";
import { useEffect } from "react";
import { useInView } from "react-intersection-observer";
import { useLocation, useNavigate } from "react-router-dom";

import { grey } from "../../../colors";
import VerbatimFeedbackModal from "../../cards/VerbatimFeedbackModal";
import { ChatResponse } from "../models";
import { FocusedThreadQuestion } from "../state/DeepchatState";
import { scrollQuestionIntoView } from "./SessionThread";
import SupportingDocuments from "./SupportingDocuments";

export interface SessionResponseItemProps {
  response: ChatResponse;
  anchorId: string;
  index: number;
}

export default function SessionResponseItem({ response, anchorId }: SessionResponseItemProps) {
  const location = useLocation();
  const navigate = useNavigate();
  const [focusedQuestion, setFocusedQuestion] = useAtom(FocusedThreadQuestion);

  // NOTE: The threshold of 0.8 isn't an exact science and may require further tuning in the future.
  const { ref, inView } = useInView({ threshold: 0.8 });

  const handleRestartThread = (questionText: string) => {
    navigate("/deepchat", { state: { user_query: questionText, trigger_enhance: true } });
  };

  useEffect(() => {
    if (inView && focusedQuestion !== response) {
      // Setting this value updates which Question is highlighted in <SessionQuestionsOutline />
      setFocusedQuestion(response);
    }
  }, [inView]);

  return (
    <Box className="session-response" sx={{ marginBottom: "2em" }}>
      <Typography variant="h5" color={"#13294B"} mb="1em">
        <Link
          href={`${location.pathname}#${anchorId}`}
          id={anchorId}
          underline="none"
          onClick={(event) => {
            event.preventDefault();
            scrollQuestionIntoView(anchorId);
          }}
        >
          {response.user_query}
        </Link>
      </Typography>

      <Box ref={ref}>
        <Box display="flex" justifyContent="space-between" alignItems="center" mb="1em">
          <Box>
            <AutoAwesomeOutlined sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
            <Typography variant="h6" color={"#13294B"} display="inline-block">
              Deepchat Answer
            </Typography>
          </Box>
          <Box>
            <Button
              disableElevation
              variant="contained"
              aria-label="enhance"
              sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
              onClick={() => handleRestartThread(response.user_query ?? "")}
            >
              <AutoFixHigh />
            </Button>
          </Box>
        </Box>
        <Paper sx={{ p: "1.5em", borderRadius: "1em" }}>{formatResponseText(response?.response || "")}</Paper>
      </Box>

      <Box mt="2em">
        <SupportingDocuments sources={response.sources} />
      </Box>

      <VerbatimFeedbackModal />
    </Box>
  );
}

// Very simplistic formatting function for handling plain text responses from RAG
export const formatResponseText = (text: string) => {
  const response = (text || "").split("\n").flatMap((segment, i) => {
    const isListItem = segment.startsWith("- ");
    const isBlankLine = !segment;

    if (isListItem) {
      // Clean off the "- " and make element a <li>
      return <li key={i}>{segment.replace(/^(- )/, "")}</li>;
    }
    return (
      <Typography py={isBlankLine ? "0.25em" : 0} key={i}>
        {segment}
      </Typography>
    );
  });

  return response;
};
