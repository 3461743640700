import { matchRoutes, useLocation, useNavigate } from "react-router-dom";
import { PageAccessList } from "./components/Authorization/Roles";
import { FindRouteById, FlattenRoutes, Routes } from "./Routes";
import useUserContext from "./components/Authorization/useUserContext";

export default function useUserNavigation() {
  const location = useLocation();
  const navigate = useNavigate();
  const user = useUserContext();

  const getRouteIds = () => {
    const currentRoutes = Array.from(matchRoutes(Routes, location) ?? []);
    const routeIds = currentRoutes.map((r) => r.route.id).filter((r) => r);
    if (!routeIds.length) {
      throw new Error(
        `Current location "${location.pathname}" does not contain a valid route ID, make sure at least one of RouteObject's in your path contains a valid ID`,
      );
    }

    return routeIds;
  };

  const isRoute = (routeId: string) => {
    // The last element in the route stack is generally considered the most specific route
    return getRouteIds().at(-1) === routeId;
  };

  const getRouteIdAccessList = () => {
    const userRoles = user.selectedRoles.length > 0 ? user.selectedRoles : user.assignedRoles;
    const pages = PageAccessList.find((_) => _.domain === userRoles.at(0))?.pages;

    if (!pages) {
      throw new Error("User does not have a page access list defined.");
    }

    return pages;
  };

  const hasAccessToCurrentRoute = () => {
    const routeIds = getRouteIdAccessList();
    const routeKeys = getRouteIds();

    const matchedRoutes = routeIds.filter((_routeId) => routeKeys.indexOf(_routeId) !== -1);
    const hasAccess = matchedRoutes.length > 0;

    console.debug("hasAccessToCurrentRoute", hasAccess, { routeIds, routeKeys, matchedRoutes });
    return hasAccess;
  };

  const navigateToHomepage = () => {
    const routeIds = getRouteIdAccessList();

    const route = FindRouteById(Routes, routeIds[0]);

    return navigate(route.path ?? "", { replace: true });
  };

  return {
    hasAccessToCurrentRoute,
    navigateToHomepage,
    navigate,
    getRouteIds,
  };
}
