import { AccountBox, DocumentScannerOutlined } from "@mui/icons-material";
import { Box, Checkbox, CircularProgress, FormControlLabel, Typography } from "@mui/material";
import { TInterview } from "../models";
import useMarketResearchStore from "../useMarketResearchStore";

export default function InterviewComponent({
  study_date,
  num_respondents,
  fromStudySummary,
  respondantIds,
}: TInterview) {
  const { isLoadingFacets, updateRespondentFilterJson, navigate, setRespondentId } = useMarketResearchStore();

  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
      <Box sx={{ display: "flex", gap: "0.5rem" }} alignItems={"center"}>
        <AccountBox />
        <Typography variant="h6" sx={{ fontSize: "20px", color: "#13294B" }}>
          Interviews
        </Typography>
      </Box>
      <Box sx={{ display: "flex", mb: "1em" }} justifyContent={"space-between"}>
        <Box sx={{ display: "flex", gap: "0.5rem" }} alignItems={"center"}>
          <Typography variant="h6" sx={{ fontSize: "10px", color: "#425466" }}>
            Fielded
          </Typography>
          <Typography variant="h6" sx={{ fontSize: "13px", color: "#004677" }}>
            {study_date}
          </Typography>
        </Box>
        <Box sx={{ padding: "0.3rem", backgroundColor: "#00A3E0", borderRadius: "10px" }}>
          <Typography variant="h6" sx={{ fontSize: "13px", color: "white" }}>
            {`${num_respondents} Respondents`}
          </Typography>
        </Box>
      </Box>
      {isLoadingFacets ? (
        <Box sx={{ display: "flex", justifyContent: "center", alignItems: "center", flex: 1 }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ flex: 1, overflow: "auto" }}>
          {respondantIds?.map((respondentId, i) => {
            return (
              <Box
                key={i}
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  mb: "0.5rem",
                  alignItems: "center",
                }}
              >
                <FormControlLabel
                  control={
                    <Checkbox
                      disabled={fromStudySummary}
                      defaultChecked
                      onClick={() => {
                        updateRespondentFilterJson(respondentId);
                      }}
                    />
                  }
                  label={<Typography variant="body2">{respondentId}</Typography>}
                />
                <Box sx={{ display: "flex", gap: "0.5rem" }}>
                  {/* <SpatialAudioOffOutlined fontSize="small" /> */}
                  <DocumentScannerOutlined
                    sx={{ cursor: "pointer" }}
                    fontSize="small"
                    onClick={() => {
                      setRespondentId(respondentId);
                      navigate(`/market-research/transcript`);
                    }}
                  />
                </Box>
              </Box>
            );
          })}
        </Box>
      )}
    </Box>
  );
}
