import { Box, Button, Typography } from "@mui/material";
import { UUID } from "crypto";
import { useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";

import { useDeepChatSocketContext } from "../../../contexts/DeepChatSocketContext";
import useMarketResearchStore from "../useMarketResearchStore";

import { BodyPanel, MainPanel, RightPanel } from "../Layout/Panels";
import ChatAnswerNode from "../StudyChat/ChatAnswerNode";
import InterviewComponent from "../ViewStudy/InterviewComponent";

export const isValidQuestion = (question: string) => {
  const text = String(question);
  const words = text.split(" ");
  return text.length > 8 && words.length > 2 && words.some((_word) => _word.length > 2);
};

const getThreadId = (value: string) => {
  return value ? (value as UUID) : undefined;
};

export default function GenerateSummary() {
  const navigate = useNavigate();
  const DeepChatSocket = useDeepChatSocketContext();
  const { messages, studyId, getStudy, isLoadingChat, socketStatusMessage, baseFilter, respondentIdsArray } =
    useMarketResearchStore();

  // Scroll the title of the most recent question up into view when the session[] updates
  useEffect(() => {
    const target = messages.at(-1);
    if (target) {
      const anchorId = `${target?.user_chat_session}-${messages.indexOf(target)}`;
      scrollQuestionIntoView(anchorId);
    }
  }, [messages]);

  // Try to get study details by its "study_key"
  const study = studyId ? getStudy(studyId) : undefined;

  if (!studyId || !study) return undefined;

  function getRespondentCount(): number {
    if (baseFilter.base_filters["respondent_id"]) {
      return baseFilter.base_filters["respondent_id"].length;
    }
    if (respondentIdsArray) {
      return respondentIdsArray.length;
    }
    return 0;
  }
  return (
    <MainPanel>
      <BodyPanel>
        <Box sx={{ display: "flex", flexDirection: "column", width: "100%", paddingBottom: "32px", padding: "2px" }}>
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
            }}
            flexDirection={"row"}
          >
            <Typography variant="h5" sx={{ color: "#004677" }}>
              {study?.study_title}
            </Typography>
            <Link to={"/market-research"} style={{ textDecoration: "none", color: "#003087" }}>
              <Button color="primary" sx={{ color: "#003087" }}>
                CHANGE STUDY
              </Button>
            </Link>
          </Box>

          {messages.map((interaction, i) => {
            const anchorId = `${String(interaction.user_chat_session)}-${i}`;
            const isLastItem = messages.at(-1) === interaction;
            return (
              <ChatAnswerNode
                key={i}
                anchorId={anchorId}
                interaction={interaction}
                isLoading={isLastItem && isLoadingChat}
                loadingStatus={socketStatusMessage}
                fromSummary={true}
              />
            );
          })}
        </Box>
      </BodyPanel>

      <RightPanel>
        <InterviewComponent
          {...study}
          fromStudySummary={true}
          num_respondents={getRespondentCount()}
          respondantIds={baseFilter.base_filters["respondent_id"] ?? respondentIdsArray}
        />

        <Button
          variant="outlined"
          sx={{ color: "#003087", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
          onClick={() => {
            navigate(-1);
          }}
        >
          BACK TO STUDY
        </Button>
      </RightPanel>
    </MainPanel>
  );
}

export const scrollQuestionIntoView = (anchorId: string) => {
  const element = document.getElementById(anchorId);
  if (element) {
    const y = element.getBoundingClientRect().top + window.scrollY - (48 + 24); // Menu + 1em padding
    window.scrollTo({ top: y, behavior: "smooth" });
  }
};
