import {
  ChevronLeft,
  LaunchOutlined,
  Logout,
  LogoutOutlined,
  Menu as MenuIcon,
  StarOutlineOutlined,
} from "@mui/icons-material";
import {
  AppBar,
  Box,
  Divider,
  IconButton,
  Link,
  Menu,
  MenuItem,
  MenuItemProps,
  SvgIconTypeMap,
  Toolbar,
  Tooltip,
  Typography,
} from "@mui/material";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import { useAtom } from "jotai";
import { useEffect, useState } from "react";
import { parseJWT } from "../utils/auth/isTokenExpired";
import useAuthorizationManager from "./Authorization/useAuthorizationManager";
import useUserContext from "./Authorization/useUserContext";
import { StyledIconButton } from "./common";
import { AvatarWithFallback } from "./common/AvatarWithFallback";
import { useCallout } from "./Deepchat/Callout";
import { QuestionIsLoadingAtom } from "./Deepchat/state/DeepchatState";
import { FILTER_WIDTH } from "./FilterDrawer";
import TopTabsMenu from "./TopTabsMenu";
import useFeatureFlag from "../featureFlags/useFeatureFlag";
import { Feature } from "../featureFlags/featureFlagConfig";

const FEEDBACK_URL = import.meta.env.VITE_APP_FEEDBACK_GSHEET_URL;
const AVATAR_SIZE = "32px";

const StyledMenuItem = ({ children, onClick, sx, disabled }: MenuItemProps) => {
  return (
    <MenuItem disabled={disabled} onClick={onClick} disableRipple sx={{ display: "flex", ...sx }}>
      {children}
    </MenuItem>
  );
};

const StyledIcon = ({ Icon }: { Icon: OverridableComponent<SvgIconTypeMap> }) => {
  return <Icon sx={{ mr: "10px", width: "20px", height: "20px" }} fontSize="medium" />;
};

const StyledMenuWithIcon = ({
  disabled,
  text,
  Icon,
  handleClick,
  sx,
}: {
  disabled: boolean;
  text: string;
  Icon: OverridableComponent<SvgIconTypeMap>;
  handleClick: () => void;
  sx?: SxProps<Theme>;
}) => {
  return (
    <StyledMenuItem disabled={disabled} onClick={handleClick} sx={sx}>
      <StyledIcon Icon={Icon} />
      <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400", color: "rgba(0, 0, 0, 0.87)" }}>
        {text}
      </Typography>
    </StyledMenuItem>
  );
};

type TopBarProps = {
  hideSearchBar?: boolean;
  hideLogoutButton?: boolean;
  hideFilterDrawer?: boolean;
  showWelcome?: boolean;
  setHideFilterDrawer?: (show: boolean) => void;
};

const TopBar = ({
  hideSearchBar,
  hideLogoutButton,
  hideFilterDrawer,
  showWelcome = true,
  setHideFilterDrawer,
}: TopBarProps): JSX.Element => {
  const AuthorizationManager = useAuthorizationManager();
  const allowRoleSwitching = useFeatureFlag(Feature.RoleSwitching);

  const user = useUserContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const theme = useTheme();
  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [Callout, closeCallout] = useCallout("See the filters you selected at any time by reopening the filter drawer");
  const [loading] = useAtom(QuestionIsLoadingAtom);

  const handleFeedbackClick = () => {
    // Open the feedback google sheet in a new tab
    window.open(FEEDBACK_URL, "_blank");
  };

  const handleLogout = async () => {
    // close menu
    setAnchorEl(null);

    AuthorizationManager.logout();
  };

  const parsedJWT = parseJWT(user.token);

  const userID = parsedJWT?.userID;
  const userRoles = user.selectedRoles;

  const marginTransition = `margin ${theme.transitions.duration.enteringScreen}ms`;
  const notHideable = undefined;

  useEffect(() => {
    if (loading) {
      closeCallout();
    }
  }, [loading, closeCallout]);

  return (
    <AppBar
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        boxShadow: "none",
        borderBottom: (theme) => `1px solid ${theme.palette.grey[200]}`,
        bgcolor: "white",
      }}
    >
      <Toolbar
        sx={{
          "&": { minHeight: "3.125em", paddingLeft: "1rem" },
          alignItems: "center",
          display: "grid",
          gridTemplateColumns: "1fr auto 1fr",
        }}
      >
        <Box display="flex" alignItems="center">
          {hideFilterDrawer !== notHideable && (
            <Box sx={{ transition: marginTransition, marginLeft: hideFilterDrawer ? 0 : 30 }}>
              <Callout placement={{ top: "100%", left: "50%" }} x="left" y="top" axis="y">
                <IconButton onClick={() => setHideFilterDrawer?.(!hideFilterDrawer)}>
                  {hideFilterDrawer ? <MenuIcon /> : <ChevronLeft />}
                </IconButton>
              </Callout>
            </Box>
          )}
          <Link href={"/"} underline="none" display="flex" alignItems="center">
            <img src="/logos/deepsense_logo.svg" height="48px" />
          </Link>
        </Box>

        <Box marginRight={hideFilterDrawer !== false ? "" : `-${FILTER_WIDTH}px`} sx={{ transition: "margin 225ms" }}>
          {!hideSearchBar && <TopTabsMenu />}
        </Box>

        {!hideLogoutButton && (
          <Box justifySelf="end">
            {userID ? (
              <StyledIconButton
                onClick={handleClick}
                sx={{
                  bgcolor: "transparent",
                  borderRadius: "4px",
                  width: "40px",
                  height: "40px",
                }}
              >
                <AvatarWithFallback
                  alt="image"
                  variant="rounded"
                  src={`https://people.roche.com/api/photo/${userID}`}
                  sx={{
                    width: AVATAR_SIZE,
                    height: AVATAR_SIZE,
                  }}
                />
              </StyledIconButton>
            ) : (
              <Tooltip title="Logout" enterTouchDelay={250}>
                <div>
                  <StyledIconButton onClick={handleLogout}>
                    <Logout />
                  </StyledIconButton>
                </div>
              </Tooltip>
            )}
          </Box>
        )}
        <Menu
          id="user-menu"
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          sx={{
            "& .MuiPaper-root": { borderRadius: 4 },
          }}
        >
          <StyledMenuItem disabled>
            <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400" }}>
              {userID}
            </Typography>
          </StyledMenuItem>
          {userRoles && userRoles.length > 0 && (
            <StyledMenuItem disabled={true}>
              <Typography variant="h6" sx={{ fontSize: "16px", fontWeight: "400" }}>
                {userRoles.includes("medical")
                  ? "Medical Role"
                  : userRoles.includes("commercial")
                    ? "Commercial Role"
                    : userRoles.includes("uslt_retro")
                      ? "OE"
                      : userRoles.includes("pmr")
                        ? "Primary Market Research"
                        : userRoles}
              </Typography>
            </StyledMenuItem>
          )}
          <Divider />
          {showWelcome && allowRoleSwitching.enabled && (
            <StyledMenuWithIcon
              disabled={false}
              Icon={StarOutlineOutlined}
              handleClick={() => {
                AuthorizationManager.selectRole();
              }}
              text="Back to Welcome"
            />
          )}

          <StyledMenuWithIcon
            disabled={!FEEDBACK_URL}
            Icon={LaunchOutlined}
            handleClick={handleFeedbackClick}
            text="Give Feedback"
          />
          <Divider />
          <StyledMenuWithIcon Icon={LogoutOutlined} handleClick={handleLogout} text="Logout" disabled={!userID} />
        </Menu>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
