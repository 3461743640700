import { AccountBox } from "@mui/icons-material";
import { Box, Button, Typography } from "@mui/material";
import { rgb } from "d3";
import useMarketResearchStore from "../useMarketResearchStore";

type TDisplayItems = {
  header: string;
  value: string;
};

const DisplayItems = ({ header, value }: TDisplayItems) => {
  return (
    <Box sx={{ display: "flex", gap: "0.5rem" }} alignItems={"center"}>
      <Typography variant="body2" sx={{ color: rgb(66, 84, 102, 0.6), fontSize: "10px" }}>
        {header}
      </Typography>

      <Typography variant="body2" sx={{ fontSize: "12px" }}>
        {value}
      </Typography>
    </Box>
  );
};
export default function InterviewTranscript() {
  const { transcripts, navigate } = useMarketResearchStore();
  return (
    <Box sx={{ flex: 1, justifyContent: "space-between", display: "flex", flexDirection: "column" }}>
      <Box sx={{ display: "flex", flexDirection: "column" }}>
        <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Box sx={{ display: "flex", gap: "0.5rem" }} alignItems={"center"}>
            <AccountBox />
            <Typography variant="h6" sx={{ fontSize: "20px", color: "#13294B" }}>
              Interview Transcript
            </Typography>
          </Box>
          {/* <FileDownload /> */}
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "0.5rem",
            marginTop: "1rem",
            justifyContent: "space-between",
          }}
        >
          <DisplayItems header="RESPONDENT ID" value={`${transcripts?.respondent_id}`} />
          <DisplayItems header="PERSONA" value={transcripts?.persona ?? ""} />
          <DisplayItems header="HEIR" value={transcripts?.heir ?? ""} />
          <DisplayItems header="TARGET PRIORITY" value={transcripts?.target_priority ?? ""} />
          <DisplayItems header="SPECIALTY" value={transcripts?.specialty ?? ""} />
          <DisplayItems header="PRACTICE SEGMENT" value={transcripts?.practice_segment ?? ""} />
        </Box>
      </Box>

      <Button
        variant="outlined"
        sx={{ color: "#003087", width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}
        onClick={() => {
          navigate(-1);
        }}
      >
        BACK TO STUDY
      </Button>
    </Box>
  );
}
