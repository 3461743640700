import { UUID } from "crypto";

export enum SortDirEnum {
  asc = "asc",
  desc = "desc",
}

export type TopicSentimentFilters = {
  topics?: string;
  subtopic?: string;
  sentiment?: string;
};

export type InquirySourceBreakdownType = "direct_contact" | "msl_facilitated" | "med_facilitated" | "other_facilitated";

export type VisualFilters = {
  topics_sentiment: TopicSentimentFilters;
  keywords: string[];
  inquiry_source_breakdown?: InquirySourceBreakdownType[];
};

export type SearchFilters = {
  sort?: Sort | null;
  permissions?: SearchPermissions;
} & SearchBase;

export type SearchBase = {
  base_filters: Partial<Record<keyof SearchResults, string[]>>;
  visual_filters: VisualFilters;
  query: string | null;
  semantic: boolean;
  dates: {
    start: string | null;
    end: string | null;
  };
  dynamic_date_range?: string | null;
  join_ids: string[];
  stack_related: boolean;
};

export type SearchPermissions = {
  data_filters: Record<string, string[]>;
};

export type Sort = Partial<Record<keyof SearchResults, SortDirEnum>>;

export type RowType = {
  name: string;
  values: string[] | number[];
};

export type BaseResult = {
  _id: string;
  _index: string; // "other",
  data_source: string; // "USLT Retros",
  document_type: string; // "oe",
};

// combined verbatim type for medical and commercial
// _id is indicated as optional, in case this data is not available;
// other optional keys belong to either medical or commercial, but not both
export type SearchResults = BaseResult & {
  account_name: string;
  account_type: string;
  conference?: string;
  corp_account: string;
  crm_discussion_note?: string; // medical
  crm_insight_id?: string; // medical
  crm_interaction_id: string;
  crm_interaction_name: string;
  customer_source?: string; // commercial
  customer_type: string;
  ecosystem_name: string;
  hcp_descriptor?: string; // commercial
  indication: string;
  indication_imputed: string;
  inquiry_id: string; // medical
  inquiry_type?: string; // commercial
  key_insight_priority?: string; // medical
  keywords: string;
  leibniz_filter_date?: number;
  oasis_load_date: string;
  patient_descriptor?: string; // commercial
  product_molecule: string;
  product_molecule_imputed: string;
  product_url?: string; // commercial
  region: string;
  role_type?: string; // medical
  sentiment: string;
  source_customer_type: string;
  source_filter_date: string;
  source_indication: string;
  source_product: string;
  document_title: string;
  source_therapeutic_area?: string; // medical
  sub_indication: string;
  submitted_by: string;
  surveyid: string;
  text: string;
  text_summary: string;
  therapeutic_area: string;
  therapeutic_area_imputed: string;
  topic1: string;
  topic2: string;
  topics: string;
  subtopic: string;

  // Added for PMR
  study: string;
  respondent_id: string;

  children?: SearchResults[];
};

export type OutcomeRecord = BaseResult & {
  _score: number; // 1,
  sentiment: string; // "tailwinds",
  date_range: string; // "2024 Q1",
  outcome_id: number; // 5,
  outcome_name: string; // "ENCOURAGE RESILIENCE AND RECOGNITION",
  topics: string; // "Organizational Culture",
  flag_action: string; // "I",
  outcome_category_id: number; // 5,
  join_id: string;
  document_id: number; // 1,
  source_filter_date: string; // "2024-01-01 00:00:00.000",
  document_name: string; // "Q1 2024 Ophthalmology Outcomes Retro.pptx",
  outcome_category: string; // "BETTER EVERYDAY",
  retro_comments: string; // "Meaningful progress is being made to improve the culture with the FRMs",
  portfolio: string; // "OMNI",
  text: string; // "Meaningful progress is being made to improve the culture with the FRMs",
  outcome_description: string; // "Network is motivated and courageous everyday as we embed ICX, HE, and Ophtha culture commitments",
  therapeutic_area: string; // "Optha",
  oasis_load_date: string; // "2024-05-05 00:00:00.000000",
  retro_category_id: number; // 1,
  feedback_type: string; // "What went well?",
  product_molecule: string; //  ""
  key_impact_measurement?: string;

  children?: OutcomeRecord[];
};

export type SearchRequestResult = {
  search_id: string;
  search_request: SearchFilters;
  total_records: number;
  records_size: number;
  records: SearchResults[];
};

export type SaveSearchResponse = {
  search_id: UUID;
};

export interface CommonCardProps {
  _id: string;
  text?: string;
  text_summary?: string;
  sentiment: string;
  submitted_by?: string;
  account_name?: string;
  data_source?: string;
  topics?: string;
  subtopic?: string;
  children?: any[];
}

export const mapSearchResultToCommonCard = (searchResult: SearchResults): CommonCardProps => ({
  _id: searchResult._id,
  text: searchResult.text,
  text_summary: searchResult.text_summary,
  sentiment: searchResult.sentiment,
  submitted_by: searchResult.submitted_by,
  account_name: searchResult.account_name,
  children: searchResult.children,
  data_source: searchResult.data_source,
  topics: searchResult.topics,
  subtopic: searchResult.subtopic,
});
