import { ThumbDownAlt, ThumbDownAltOutlined, ThumbUpAlt, ThumbUpAltOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import { UUID } from "crypto";
import { useEffect, useState } from "react";
import useDeepchat from "../../contexts/useDeepchat";
import { ThreadFeedback } from "../Deepchat/models";

type FeedbackProps = {
  interactionId: UUID;
};

export default function Feedback({ interactionId }: FeedbackProps) {
  const Deepchat = useDeepchat();
  const [feedback, setFeedback] = useState<ThreadFeedback>({ feedback: 0 });

  useEffect(() => {
    if (feedback.feedback !== 0 && feedback.action_id) {
      Deepchat.submitSessionFeedback(feedback);
    }
  }, [feedback, interactionId]);

  const handleThumbsUp = () => {
    setFeedback({
      action_id: interactionId,
      feedback: 1,
    });
  };
  const handleThumbsDown = () => {
    setFeedback({
      action_id: interactionId,
      feedback: -1,
    });
  };

  const ThumbsUpIcon = () => {
    const IconComponent = feedback.feedback === 1 ? ThumbUpAlt : ThumbUpAltOutlined;
    return <IconComponent onClick={handleThumbsUp} sx={{ cursor: "pointer" }} />;
  };

  const ThumbsDownIcon = () => {
    const IconComponent = feedback.feedback === -1 ? ThumbDownAlt : ThumbDownAltOutlined;
    return <IconComponent onClick={handleThumbsDown} sx={{ cursor: "pointer" }} />;
  };
  return (
    <Box sx={{ display: "flex", gap: "0.75em" }}>
      <ThumbsUpIcon />
      <ThumbsDownIcon />
    </Box>
  );
}
