import { History } from "@mui/icons-material";
import { Box, Skeleton, Typography } from "@mui/material";
import dayjs from "dayjs";
import relativeTime from "dayjs/plugin/relativeTime";
import { useAtom } from "jotai";
import { useMemo } from "react";
import { useRecentThreads } from "../../../store/home/useRecentThreads";
import RecentThreadItem from "../RecentThreadItem";
import { QuestionTargetSalesData } from "../state/DeepchatState";
dayjs.extend(relativeTime);

export default function RecentThreads() {
  const { historyLoading, history } = useRecentThreads();
  const [checkboxSalesData] = useAtom(QuestionTargetSalesData);

  const showSkeleton = () => {
    return [...Array(4).keys()].map((i) => (
      <Skeleton key={i} variant="rounded" height={42} sx={{ marginTop: "1em" }} />
    ));
  };

  return useMemo(
    () => (
      <Box sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
        <Box>
          <History sx={{ fill: "#13294B", verticalAlign: "sub", marginRight: "0.5em" }} />
          <Typography variant="h6" color={"#13294B"} display="inline-block">
            Recent Threads
          </Typography>
        </Box>

        {historyLoading ? (
          showSkeleton()
        ) : (
          <Box
            sx={{
              flex: "1",
              "& .history-item": {
                marginTop: "1em",
                cursor: "pointer",
              },
              "& .history-title": {
                fontWeight: "bold",
                fontSize: "0.9em",
                color: "black",
                "&:hover": {
                  color: "rgb(0, 48, 135)",
                },
              },
              "& .history-timestamp": {
                fontSize: "0.8em",
                marginTop: "0.5em",
              },
            }}
          >
            {!checkboxSalesData &&
              // TODO: This is only set up for the response signature from vanilla DeepChat
              history.map((thread, i) => {
                try {
                  const item = (thread ?? []).at(0);
                  if (item) {
                    return <RecentThreadItem key={i} chatResponse={item} />;
                  }
                } catch (e) {
                  console.error(e);
                }
              })}
          </Box>
        )}
      </Box>
    ),
    [history, historyLoading],
  );
}
