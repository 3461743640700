import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import { ChatResponse } from "./models";
import dayjs from "dayjs";
import { History } from "@mui/icons-material";

type RecentThreadItemProps = {
  chatResponse: ChatResponse;
};

export default function RecentThreadItem({ chatResponse }: RecentThreadItemProps) {
  return (
    <Box className="history-item">
      <Link
        to={`/deepchat/threads/${chatResponse.user_chat_session}`}
        style={{ textDecoration: "none", color: "inherit" }}
      >
        <Typography className="history-title">{chatResponse.user_query}</Typography>
        {chatResponse.timestamp && (
          <Typography className="history-timestamp">
            <History sx={{ verticalAlign: "sub", marginRight: "0.5em", fontSize: "1.25em" }} />
            {dayjs.unix(chatResponse.timestamp).from(dayjs())}
          </Typography>
        )}
      </Link>
    </Box>
  );
}
