import { LoadingButton } from "@mui/lab";
import { Box, Skeleton, Typography as T } from "@mui/material";
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry";
import useVerbatimsInfinitePaging from "../../../contexts/useVerbatimsInfinitePaging";
import { mapSearchResultToCommonCard, OutcomeRecord, SearchResults, Sort } from "../../../models/search";
import VerbatimCard from "./VerbatimCard";
import OutcomeCard from "./OutcomeCard";

const NUM_SKELETON_CARDS = 6;

const VerbatimsCards = ({ sort }: { sort: Sort | null }) => {
  const { data, fetchNextPage, isFetching, isFetchingNextPage, hasNextPage } = useVerbatimsInfinitePaging({ sort });

  if (!isFetching && (!data || data.pages.length === 0)) {
    return (
      <Box display="flex" flexDirection="row" justifyContent="center">
        <T>No records to show.</T>
      </Box>
    );
  }

  return (
    <Box sx={{ paddingBottom: "16px" }}>
      <T sx={{ color: "#13294B", fontSize: 12, mb: 2 }}>
        Text Summarization is an experiment and may contain inaccuracies. Do not rely on summaries for business
        decisions, please review the underlying record.
      </T>
      <ResponsiveMasonry columnsCountBreakPoints={{ 350: 1, 900: 2, 1200: 3, 2000: 4 }}>
        <Masonry gutter="15px">
          {!isFetching || isFetchingNextPage
            ? (data?.pages || []).map((group, i) =>
                (group.records || []).map((record, j) => {
                  return record.document_type == "oe" ? (
                    <OutcomeCard key={`${i}:${j}`} record={record as OutcomeRecord} />
                  ) : (
                    <VerbatimCard
                      key={`${i}:${j}`}
                      record={mapSearchResultToCommonCard(record as SearchResults)}
                      fromSupportingDocs={false}
                    />
                  );
                }),
              )
            : [...Array(NUM_SKELETON_CARDS)].map((_, i) => <Skeleton key={i} variant="rounded" height={250} />)}
        </Masonry>
      </ResponsiveMasonry>
      {(!isFetching || isFetchingNextPage) && hasNextPage && (
        <Box sx={{ textAlign: "center", paddingTop: "15px" }}>
          <LoadingButton
            loading={isFetchingNextPage}
            sx={{ backgroundColor: "#e0e0e0", padding: "6px 4em" }}
            onClick={() => fetchNextPage()}
          >
            Show More
          </LoadingButton>
        </Box>
      )}
    </Box>
  );
};

export default VerbatimsCards;
